import React, { useEffect } from 'react';
import { Row } from "reactstrap";

import MetaTags from 'react-meta-tags';

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Images
import CardCustomers from './card-customers';

const Customers = (props) => {
    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Clientes", link: "#" },
    ]

    useEffect(() => {
        props.setBreadcrumbItems('Clientes', breadcrumbItems);
        
    }, [])

    return (
        <React.Fragment>
            <MetaTags>
                <title>Clientes | Grupo Terra</title>
            </MetaTags>

            <Row>
                <CardCustomers />
            </Row>
        </React.Fragment>
    );
}

export default connect(null, { setBreadcrumbItems })(Customers);