import React, { useState } from 'react';
import {
    Col,
    Card,
    Row,
    CardBody,
    Button,
} from "reactstrap";
import { Link } from "react-router-dom";

import { useAuth } from 'hooks/useAuth';
import { post } from 'helpers/api_helper';
import { useEffect } from 'react';
import hasImage from 'common/hasImage';
import LoadingHandlerScreen from 'components/Common/LoadingHandlerScreen';
import { MDBDataTable } from 'mdbreact';

const ActionButton = ({ id }) => {
    return (
        <>
            <Link to={`/detalhes-usuario-terra/${id}`}>
                <Button color="primary" size="sm" className="waves-effect waves-light">
                    <i className="mdi mdi-magnify"></i>
                </Button>
            </Link>
            {" "}
            <Link to={`/atualizar-usuario-terra/${id}`}>
                <Button color="primary" size="sm" className="waves-effect waves-light">
                    <i className="mdi mdi-pencil"></i>
                </Button>
            </Link>
        </>
    )
};

export default function CardUser() {

    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const { userData: { id_admin } } = useAuth();

    useEffect(() => {
        const fetchData = async () => {
            const payload = { id_admin_user: id_admin };
            const response = await post('user_list.php', payload);

            if (response.success) {
                const origin_users = response.users;
                const temp_users = origin_users.map(obj => {
                    return {
                        ...obj,
                        acao: <ActionButton id={obj.id} />,
                        image: <img alt={obj.buyer_name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
                    }
                })
                setUsers(temp_users);
                setIsLoading(false);
            };
        };

        fetchData();
    }, [id_admin]);

    const data = {
        columns: [
            {
                label: "Imagem",
                field: "image",
                sort: "disabled",
                width: 150,
            },
            {
                label: "Nome",
                field: "full_name",
                sort: "asc",
                width: 350,
            },
            {
                label: "Título",
                field: "title",
                sort: "disabled",
                width: 250,
            },
            {
                label: "Ação",
                field: "acao",
                width: 250,
                sort: "disabled"
            },
        ],
        rows: users,
    };

    return <LoadingHandlerScreen isLoading={isLoading} component={
        <Row>
            <Col className="col-12">
                <Card>
                    <CardBody>
                        <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={data} />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    } />
};
