import React, { useEffect, useState, useRef } from "react";
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Row, Button, InputGroup, Input, InputGroupAddon, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import hasImage from "common/hasImage";
import { get, post } from "helpers/api_helper";
import { useCompanyContext } from "contexts/CompanyContextProvider";
import { useNavigate, useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import toast from "react-hot-toast";
import { useAuth } from "hooks/useAuth";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";

const CreateBranchEmployee = (props) => {

  const { id } = useParams();

  const { userData: { id_admin } } = useAuth();

  const navigate = useNavigate();

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Compradores", link: "/compradores" },
    { title: "Editar Comprador", link: "#" },
  ];

  // Estado para armazenar os dados do formulário
  const [formData, setFormData] = useState({
    full_name: "",
    cpf: "",
    function_id: 1,
    email: "",
    password: "",
    address: "",
    complement: "",
    city: "",
    zip_code: "",
    whatsapp: "",
    cellphone: "",
    is_master_user: false,
    is_shopping_center: false,
    image: "",
  });

  const [isVisible, setIsVisible] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [brStates, setBrStates] = useState([]);

  // Referência para o input de arquivo
  const fileInputRef = useRef(null);

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {

    const file = e.target.files[0];

    if (file) {

      setImageLoading(true); // Indica que a imagem está sendo carregada
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const aspectRatio = img.width / img.height;
          let newWidth, newHeight;

          // Definir dimensões de corte mantendo a proporção 1:1 (500x500)
          if (aspectRatio > 1) {
            newWidth = 500;
            newHeight = 500 / aspectRatio;
          } else {
            newWidth = 500 * aspectRatio;
            newHeight = 500;
          };

          // Centralizar a imagem no canvas
          const offsetX = (500 - newWidth) / 2;
          const offsetY = (500 - newHeight) / 2;

          // Desenhar a imagem cortada no canvas
          canvas.width = 500;
          canvas.height = 500;
          ctx.drawImage(img, offsetX, offsetY, newWidth, newHeight);

          // Converter o canvas de volta para o formato base64
          const croppedImage = canvas.toDataURL('image/jpeg');

          setFormData({
            ...formData,
            image: croppedImage,
          });

          setImageLoading(false); // Indica que a imagem foi completamente carregada e processada
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const getUF = () => {
    get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
      .then(res => {
        if (res) {
          setBrStates(res);
        };
      })
  };

  const CreateUpdateEmployee = () => {
    return (
      <Button color={'primary'} className="waves-effect waves-light w-100" onClick={handleSubmit}>
        <i className="mdi mdi-pencil"></i> Atualizar Comprador
      </Button>
    );
  };

  const getUserData = () => {

    get('details_holding_buyer.php?id=' + id)
      .then(res => {

        if (res.success) {

          setFormData(res.user_data);
          setIsLoading(false)
        };
      });

  };

  useEffect(() => {

    props.setBreadcrumbItems("Editar Comprador", breadcrumbItems);

    getUserData();
    getUF();

  }, []);

  useEffect(() => {

    const formatted_zip_code = formData.zip_code?.replace(/\D/g, '');

    if (formatted_zip_code?.length > 7) {
      get(`https://viacep.com.br/ws/${formatted_zip_code}/json/`)
        .then(res => {
          if (res) {
            setFormData({ ...formData, address: res.logradouro, neighborhood: res.bairro, city: res.localidade, state: res.uf });
          };
        });
    };

  }, [formData.zip_code]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar se todos os campos obrigatórios foram preenchidos
    if (
      formData.full_name &&
      formData.cpf &&
      formData.function_id &&
      formData.email &&
      formData.address &&
      formData.city &&
      formData.zip_code &&
      formData.whatsapp &&
      formData.cellphone
    ) {

      // Construir o payload
      const payload = {
        id_admin_user: id_admin,
        image: formData.image,
        id_user: id,
        company_id: id,
        cpf: formData.cpf,
        email: formData.email,
        password: formData.password,
        full_name: formData.full_name,
        address: formData.address,
        number: formData.number,
        complement: formData.complement,
        neighborhood: formData.neighborhood,
        city: formData.city,
        state: formData.state,
        zip_code: formData.zip_code,
        whatsapp: formData.whatsapp,
        cellphone: formData.cellphone,
        buying_limit: formData.buying_limit,
        function_id: formData.function_id,
        is_terra: 0,
        is_master_user: formData.is_master_user ? 1 : 0,
        is_shopping_center: formData.is_shopping_center ? 1 : 0,
      };

      post("update_holding_buyer.php", payload)
        .then((response) => {

          if (response.success) {
            toast.success("Comprador adicionado com sucesso!");
            navigate(`/colaborador-detalhes/${id}`);
          } else {
            toast.error(response.message);
          };
        })
        .catch((error) => {
          toast.error("Erro de conexão! Tente novamente mais tarde.");
          console.error("Erro ao enviar payload:", error);
        });

    } else {

      console.log("full_name:", formData.full_name);
      console.log("cpf:", formData.cpf);
      console.log("function_id:", formData.function_id);
      console.log("email:", formData.email);
      console.log("address:", formData.address);
      console.log("city:", formData.city);
      console.log("zip_code:", formData.zip_code);
      console.log("whatsapp:", formData.whatsapp);
      console.log("cellphone:", formData.cellphone);

      toast.error("Por favor, preencha todos os campos obrigatórios");
    };
  };

  return (
    <LoadingHandlerScreen isLoading={isLoading} component={<React.Fragment>
      <MetaTags>
        <title>Editar Comprador | Grupo Terra</title>
      </MetaTags>
      <form>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12} sm={3}>
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      style={{ display: 'none' }}
                      ref={fileInputRef}
                      onChange={handleFileChange}
                    />
                    <Button onClick={handleImageClick}>
                      {imageLoading ? "Carregando..." : (
                        <img
                          className="img-thumbnail"
                          alt="Imagem"
                          width="200"
                          src={hasImage(formData.image)}
                        />
                      )}
                    </Button>
                  </Col>
                  <Col xs={12} sm={9}>
                    <div className="mt-5 mt-lg-4">
                      <h5 className="font-size-14 mb-4">
                        <i className="mdi mdi-arrow-right text-primary me-1"></i>{" "}
                        Informações do Colaborador
                      </h5>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Nome Completo *
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-input"
                            name="full_name"
                            value={formData.full_name}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          CPF
                        </label>
                        <div className="col-sm-9">
                          <div className="col-sm-9">
                            <ReactInputMask
                              mask='999.999.999-99'
                              className="form-control"
                              id="horizontal-firstname-input"
                              value={formData.cpf}
                              name="cpf"
                              onChange={handleInputChange}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="cargo"
                          className="col-md-3 col-form-label"
                        >
                          Função
                        </label>
                        <div className="col-md-9">
                          <select
                            className="form-control"
                            id="function_id"
                            name="function_id"
                            value={formData.function_id}
                            onChange={handleInputChange}
                          >
                            <option value='0'>Compras</option>
                            <option value='1'>Direção</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInlineShoppingCenter"
                              checked={formData.is_shopping_center}
                              name="is_shopping_center"
                              onChange={handleInputChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInlineShoppingCenter"
                            >
                              Central de Compras
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInlineMasterUser"
                              name="is_master_user"
                              checked={formData.is_master_user}
                              onChange={handleInputChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInlineMasterUser"
                            >
                              Usuário Administrador
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Dados de Acesso e Limites
                </CardTitle>
                <Row>
                  <Col xs={12}>
                    <div className="row mb-4">
                      <label
                        htmlFor="horizontal-email-input"
                        className="col-sm-3 col-form-label"
                      >
                        E-mail
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="horizontal-email-input"
                          name="email"
                          value={formData.email}
                          onChange={handleInputChange}
                          autoComplete="off"
                        />
                      </div>
                    </div>
                    <Row className="mb-3">
                      <label htmlFor="horizontal-email-input" className="col-sm-3 col-form-label">Senha</label>
                      <div className="col-sm-9">
                        <InputGroup>
                          <Input type={isVisible ? "text" : 'password'}
                            placeholder="**********"
                            className="form-control"
                            name="password"
                            value={formData.password}
                            onChange={handleInputChange}
                            autoComplete="off"
                          />
                          <InputGroupAddon addonType="append"><Button onClick={() => setIsVisible(!isVisible)} color="primary"><i className={"mdi mdi-eye" + (isVisible ? "-off" : '')}></i></Button></InputGroupAddon>
                        </InputGroup>
                      </div>
                    </Row>
                    <div className="row mb-4">
                      <label
                        htmlFor="horizontal-email-input"
                        className="col-sm-3 col-form-label"
                      >
                        Whatsapp
                      </label>
                      <div className="col-sm-9">
                        <div className="col-sm-9">
                          <ReactInputMask
                            mask='(99) 9 9999-9999'
                            className="form-control"
                            id="horizontal-whatsapp-input"
                            name="whatsapp"
                            value={formData.whatsapp}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label
                        htmlFor="horizontal-email-input"
                        className="col-sm-3 col-form-label"
                      >
                        Telefone
                      </label>
                      <div className="col-sm-9">
                        <div className="col-sm-9">
                          <ReactInputMask
                            mask='(99) 9 9999-9999'
                            className="form-control"
                            id="horizontal-cellphone-input"
                            name="cellphone"
                            value={formData.cellphone}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Limite de Compra
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            id="horizontal-firstname-input"
                            name="buying_limit"
                            value={formData.buying_limit}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Endereço
                </CardTitle>
                <Row>
                  <Col>
                    <Row>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="zip_code" className="col-form-label">CEP</label>
                        <div className="col-md-10">
                          <ReactInputMask
                            mask='99.999-999'
                            id="zip_code"
                            className="form-control"
                            type="text"
                            name="zip_code"
                            value={formData.zip_code}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="address" className="col-form-label">Endereço</label>
                        <div className="col-md-10">
                          <input
                            id="address"
                            className="form-control"
                            type="text"
                            name="address"
                            value={formData.address}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="number" className="col-form-label">Número</label>
                        <div className="col-md-10">
                          <input
                            id="number"
                            className="form-control"
                            type="text"
                            name="number"
                            value={formData.number}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="complement" className="col-form-label">Complemento</label>
                        <div className="col-md-10">
                          <input
                            id="complement"
                            className="form-control"
                            type="text"
                            name="complement"
                            value={formData.complement}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="neighborhood" className="col-form-label">Bairro</label>
                        <div className="col-md-10">
                          <input
                            id="neighborhood"
                            className="form-control"
                            type="text"
                            name="neighborhood"
                            value={formData.neighborhood}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="city" className="col-form-label">Cidade</label>
                        <div className="col-md-10">
                          <input
                            id="city"
                            className="form-control"
                            type="text"
                            name="city"
                            value={formData.city}
                            onChange={handleInputChange}
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="state" className="col-form-label">Estado</label>
                        <div className="col-md-10">
                          <select
                            id="state"
                            className="form-control"
                            name="state"
                            value={formData.state}
                            onChange={handleInputChange}>
                            <option value="">Selecione um Estado</option>
                            {
                              brStates.map(state => (
                                <option key={state.id} value={state.sigla}>{state.sigla}</option>
                              ))
                            }
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <CreateUpdateEmployee />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>} />
  );
};

export default connect(null, { setBreadcrumbItems })(CreateBranchEmployee);