import React, { useEffect } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    Badge,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";

import MetaTags from 'react-meta-tags';

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

//Import Images
import { useState } from 'react';
import { get } from 'helpers/api_helper';
import { useAuth } from 'hooks/useAuth';
import hasImage from 'common/hasImage';
import LoadingHandlerScreen from 'components/Common/LoadingHandlerScreen';

const OrderDetails = (props) => {

    const { id } = useParams();

    const [orderData, setOrderData] = useState({});
    const [companyData, setCompanyData] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    const { userData: { id_admin } } = useAuth({});

    const breadcrumbItems = [
        { title: "Dashboard", link: "/dashboard" },
        { title: "Pedidos", link: "/pedidos" },
        { title: "Detalhes de Pedido", link: "#" },
    ];

    const fetchData = async () => {
        const response = await get(`order_details.php?id_order=${id}`);
        if (response.success) {
            setOrderData(response.order);
            setCompanyData(response.order.customer_data);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        props.setBreadcrumbItems('Detalhes de Pedido', breadcrumbItems);
        fetchData();
    }, [id_admin]);

    const { delivery_address } = companyData;

    //Print the Invoice
    const printInvoice = () => {
        window.print()
    };

    return (<LoadingHandlerScreen isLoading={isLoading} component={
        <React.Fragment>
            <MetaTags>
                <title>Detalhes de Pedido | Grupo Terra</title>
            </MetaTags>
            <Row>
                <Col xs="12">
                    <Card>
                        <CardBody>
                            <Row>
                                <Col xs="12">
                                    <div className="invoice-title">
                                        <h4 className="float-end font-size-16"><strong>Pedido #{orderData?.order_number}</strong></h4>
                                        <h3 className="mt-0">
                                            <img src={hasImage(companyData?.company_logo)} alt="logo" height="100" />
                                        </h3>
                                    </div>
                                    <hr />
                                    <Row>
                                        <Col md="3">
                                            <address>
                                                <strong>Empresa:</strong><br />
                                                {companyData?.company_name}
                                            </address>
                                        </Col>
                                        <Col md="3">
                                            <address>
                                                <strong>Comprador:</strong><br />
                                                <Link to={'/colaborador-detalhes/' + companyData['user_id']}>
                                                    <img src={hasImage(companyData.user_image)} alt="user" className="avatar-xs me-2 rounded-circle" /> {companyData?.user_name}
                                                </Link>
                                            </address>
                                        </Col>
                                        <Col md="3">
                                            <address>
                                                <strong>Responsável:</strong><br />
                                                {companyData?.responsible_name + " (" + companyData?.responsible_function + ")"}
                                            </address>
                                        </Col>
                                        <Col md="3" className="text-end">
                                            <address>
                                                <strong>Endereço de Entrega:</strong><br />
                                                {delivery_address?.address}, {delivery_address?.number}<br />
                                                {delivery_address?.complement}<br />
                                                {delivery_address?.neighborhood}<br />
                                                {delivery_address?.city} - {delivery_address?.state}
                                            </address>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs="6" className="mt-4">
                                            <address>
                                                <strong>Forma de Pagamento:</strong><br />
                                                <Badge className={"rounded-pill bg-success"}><strong>{orderData?.payment_method}</strong></Badge>
                                            </address>
                                        </Col>
                                        <Col xs="6" className="mt-4 text-end">
                                            <address>
                                                <strong>Data do Pedido:</strong><br />
                                                {orderData?.order_date}<br />
                                            </address>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xs="12">
                                    <div>
                                        <div className="p-2">
                                            <h3 className="font-size-16"><strong>Lista de Produtos</strong></h3>
                                        </div>
                                        <div className="">
                                            <div className="table-responsive">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <td><strong>ID</strong></td>
                                                            <td>Imagem</td>
                                                            <td><strong>Nome</strong></td>
                                                            <td><strong>NCM</strong></td>
                                                            <td className="text-center"><strong>Preço</strong></td>
                                                            <td className="text-center"><strong>Quantidade</strong></td>
                                                            <td className="text-end"><strong>Total</strong></td>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            orderData?.products_data?.map((item, index) => (
                                                                <tr key={index}>
                                                                    <td>{item.product_id}</td>
                                                                    <td>
                                                                        <img alt={item.product_name} className="rounded avatar-sm card-img" src={hasImage(item.image)} />
                                                                    </td>
                                                                    <td>{item.product_name}</td>
                                                                    <td>{item.ncm}</td>
                                                                    <td className="text-center">{item.product_price}</td>
                                                                    <td className="text-center">{item.product_quantity}</td>
                                                                    <td className="text-end">{item.product_total_price}</td>
                                                                </tr>
                                                            ))
                                                        }
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line"></td>
                                                            <td className="thick-line text-center">
                                                                <strong>Subtotal</strong>
                                                            </td>
                                                            <td className="thick-line text-end">{orderData?.subtotal_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Cashback</strong>
                                                            </td>
                                                            <td className="no-line text-end">{orderData.discount_cashback}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Desconto Empresa</strong>
                                                            </td>
                                                            <td className="no-line text-end">{orderData.discount_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Frete</strong>
                                                            </td>
                                                            <td className="no-line text-end">{orderData.shipping_price}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="thick-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line"></td>
                                                            <td className="no-line text-center">
                                                                <strong>Total</strong>
                                                            </td>
                                                            <td className="no-line text-end">
                                                                <h4 className="m-0">{orderData.total_price}</h4>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="d-print-none">
                                                <div className="float-end">
                                                    <Link to="#" onClick={printInvoice} className="btn btn-info waves-effect waves-light">
                                                        <i className="mdi mdi-file-pdf"> </i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </Col>
                            </Row>

                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    } />
    );
};

export default connect(null, { setBreadcrumbItems })(OrderDetails);