import React, { useEffect, useState, } from "react";
import MetaTags from 'react-meta-tags';
import { Card, CardBody, Col, Row, CardTitle } from "reactstrap";
import { connect } from "react-redux";
import { setBreadcrumbItems } from "../../store/actions";
import hasImage from "common/hasImage";
import { get } from "helpers/api_helper";
import { useParams } from "react-router-dom";
import ReactInputMask from "react-input-mask";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";

const CustomerEmployeeDetails = (props) => {

  const { id } = useParams();

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Compradores", link: "/compradores" },
    { title: "Detalhes de Comprador", link: "#" },
  ];

  // Estado para armazenar os dados do formulário
  const [formData, setFormData] = useState({
    full_name: "",
    cpf: "",
    function_id: 0,
    email: "",
    password: "",
    address: "",
    complement: "",
    city: "",
    zip_code: "",
    whatsapp: "",
    cellphone: "",
    is_master_user: false,
    is_shopping_center: false,
    image: "",
  });

  const [brStates, setBrStates] = useState([]);

  const [isLoading, setIsLoading] = useState(true);


  // Referência para o input de arquivo

  const getUF = () => {
    get('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
      .then(res => {
        if (res) {
          setBrStates(res);
        };
      })
  };

  const getUserData = () => {

    get('details_holding_buyer.php?id=' + id)
      .then(res => {

        if (res.success) {

          setFormData(res.user_data);
          setIsLoading(false);
        };
      });

  };

  useEffect(() => {

    props.setBreadcrumbItems("Detalhes de Comprador", breadcrumbItems);

    getUserData();
    getUF();

  }, []);

  return (<LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>
      <MetaTags>
        <title>Detalhes de Comprador | Grupo Terra</title>
      </MetaTags>
      <form>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col xs={12} sm={3}>
                    <img
                      className="img-thumbnail"
                      alt="Imagem"
                      width="200"
                      src={hasImage(formData.image)}
                    />
                  </Col>
                  <Col xs={12} sm={9}>
                    <div className="mt-5 mt-lg-4">
                      <h5 className="font-size-14 mb-4">
                        <i className="mdi mdi-arrow-right text-primary me-1"></i>{" "}
                        Informações do Comprador
                      </h5>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Nome Completo *
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            className="form-control"
                            id="horizontal-firstname-input"
                            name="full_name"
                            value={formData.full_name}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          CPF
                        </label>
                        <div className="col-sm-9">
                          <div className="col-sm-9">
                            <ReactInputMask
                              mask='999.999.999-99'
                              className="form-control"
                              id="horizontal-firstname-input"
                              value={formData.cpf}
                              name="cpf"
                              disabled
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="cargo"
                          className="col-md-3 col-form-label"
                        >
                          Função
                        </label>
                        <div className="col-md-9">
                          <select
                            className="form-control"
                            id="funcao"
                            name="function_id"
                            value={formData.function_id}
                            disabled
                          >
                            <option value=''>Selecione um cargo *</option>
                            <option value='0'>Compras</option>
                            <option value='1'>Direção</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInlineShoppingCenter"
                              checked={formData.is_shopping_center}
                              name="is_shopping_center"
                              disabled
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInlineShoppingCenter"
                            >
                              Central de Compras
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInlineMasterUser"
                              name="is_master_user"
                              checked={formData.is_master_user}
                              disabled
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInlineMasterUser"
                            >
                              Usuário Administrador
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Dados de Acesso e Limites
                </CardTitle>
                <Row>
                  <Col xs={12}>
                    <div className="row mb-4">
                      <label
                        htmlFor="horizontal-email-input"
                        className="col-sm-3 col-form-label"
                      >
                        E-mail
                      </label>
                      <div className="col-sm-9">
                        <input
                          type="text"
                          className="form-control"
                          id="horizontal-email-input"
                          name="email"
                          value={formData.email}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label
                        htmlFor="horizontal-email-input"
                        className="col-sm-3 col-form-label"
                      >
                        Whatsapp
                      </label>
                      <div className="col-sm-9">
                        <div className="col-sm-9">
                          <ReactInputMask
                            mask='(99) 9 9999-9999'
                            className="form-control"
                            id="horizontal-whatsapp-input"
                            name="whatsapp"
                            value={formData.whatsapp}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-4">
                      <label
                        htmlFor="horizontal-email-input"
                        className="col-sm-3 col-form-label"
                      >
                        Telefone
                      </label>
                      <div className="col-sm-9">
                        <div className="col-sm-9">
                          <ReactInputMask
                            mask='(99) 9 9999-9999'
                            className="form-control"
                            id="horizontal-cellphone-input"
                            name="cellphone"
                            value={formData.cellphone}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Limite de Compra
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="number"
                            className="form-control"
                            id="horizontal-firstname-input"
                            name="buying_limit"
                            value={formData.buying_limit}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle className="h4 mb-4">
                  Endereço
                </CardTitle>
                <Row>
                  <Col>
                    <Row>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="zip_code" className="col-form-label">CEP</label>
                        <div className="col-md-10">
                          <ReactInputMask
                            mask='99.999-999'
                            id="zip_code"
                            className="form-control"
                            type="text"
                            name="zip_code"
                            value={formData.zip_code}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="address" className="col-form-label">Endereço</label>
                        <div className="col-md-10">
                          <input
                            id="address"
                            className="form-control"
                            type="text"
                            name="address"
                            value={formData.address}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="number" className="col-form-label">Número</label>
                        <div className="col-md-10">
                          <input
                            id="number"
                            className="form-control"
                            type="text"
                            name="number"
                            value={formData.number}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="complement" className="col-form-label">Complemento</label>
                        <div className="col-md-10">
                          <input
                            id="complement"
                            className="form-control"
                            type="text"
                            name="complement"
                            value={formData.complement}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="neighborhood" className="col-form-label">Bairro</label>
                        <div className="col-md-10">
                          <input
                            id="neighborhood"
                            className="form-control"
                            type="text"
                            name="neighborhood"
                            value={formData.neighborhood}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="city" className="col-form-label">Cidade</label>
                        <div className="col-md-10">
                          <input
                            id="city"
                            className="form-control"
                            type="text"
                            name="city"
                            value={formData.city}
                            disabled
                          />
                        </div>
                      </Col>
                      <Col className="col-sm-4 mb-3">
                        <label htmlFor="state" className="col-form-label">Estado</label>
                        <div className="col-md-10">
                          <select
                            id="state"
                            className="form-control"
                            name="state"
                            value={formData.state}
                            disabled>
                            <option value="">Selecione um Estado</option>
                            {
                              brStates.map(state => (
                                <option key={state.id} value={state.sigla}>{state.sigla}</option>
                              ))
                            }
                          </select>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </form>
    </React.Fragment>
  } />
  );
};

export default connect(null, { setBreadcrumbItems })(CustomerEmployeeDetails);