import React, { useEffect, useState } from 'react';
import {
    Col,
    Card,
    Button,
    CardBody,
    Row,
    Label,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useAuth } from 'hooks/useAuth';
import { get } from 'helpers/api_helper';
import hasImage from 'common/hasImage';
import LoadingHandlerScreen from 'components/Common/LoadingHandlerScreen';
import { MDBDataTable } from 'mdbreact';
import ReactSelect from 'react-select';
import makeAnimated from "react-select/animated";

export default function CardCustomers() {

    const [customers, setCustomers] = useState([]);

    const { userData: { id_admin } } = useAuth();
    const [isLoading, setIsLoading] = useState(true);

    const [category, setCategory] = useState(0);

    const animatedComponents = makeAnimated();

    const getPathByCategory = () => {
        switch (category.value) {
            case "0":
                return "";
            case "1":
                return "matriz-";
            case "2":
                return "filial-";
            default:
                return "";
        };
    };

    const data = {
        columns: [
            {
                label: "Imagem",
                field: "image",
                sort: "disabled",
                width: 100,
            },
            {
                label: "Nome",
                field: "fantasy_name",
                sort: "asc",
                width: 100,
            },
            {
                label: "Vendedor Responsável",
                field: "seller_name",
                sort: "asc",
                width: 200,
            },
            {
                label: "Ação",
                field: "acao",
                sort: "disabled",
                width: 100,
                sort: "disabled"
            },
        ],
        rows: customers,
    };

    useEffect(() => {

        const getCompanyByCategory = () => {
            switch (category.value) {
                case "0":
                    return "holdings";
                case "1":
                    return "matrix";
                case "2":
                    return "branches";
                default:
                    return "holdings";
            };
        };

        const fetchData = async () => {
            const payload = { id_admin_user: id_admin };
            const response = await get(`list_companies/Listagem/list_all_${getCompanyByCategory()}.php`, payload);

            if (response.success) {

                const origin_customers = () => {

                    switch (category.value) {
                        case "0":
                            return response.holdings;
                        case "1":
                            return response.matrix;;
                        case "2":
                            return response.branches;
                        default:
                            return response.holdings;
                    };
                };

                const temp_customers = origin_customers().map(obj => {
                    return {
                        ...obj,
                        acao: <ActionButton id={obj.id} />,
                        image: <img alt={obj.fantasy_name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />
                    };
                });

                setCustomers(temp_customers);
                setIsLoading(false);
            };
        };

        fetchData();
    }, [id_admin, category]);

    const optionGroup = [
        {
            label: "Categoria de Empresa",
            options: [
                { label: "Holding", value: "0" },
                { label: "Matriz", value: "1" },
                { label: "Filial", value: "2" },
            ]
        },
    ];

    const ActionButton = ({ id }) => {
        return (
            <>
                <Link to={`/${getPathByCategory()}clientes-detalhes/${id}`}>
                    <Button color="primary" size="sm" className="waves-effect waves-light">
                        <i className="mdi mdi-magnify"></i>
                    </Button>
                </Link>
                {" "}
                <Link to={`/${getPathByCategory()}cliente-editar/${id}`}>
                    <Button color="primary" size="sm" className="waves-effect waves-light">
                        <i className="mdi mdi-pencil"></i>
                    </Button>
                </Link>
                {" "}
                <Link to={`/cadastrar-colaborador-cliente/${id}`}>
                    <Button color="primary" size="sm" className="waves-effect waves-light">
                        <i className="mdi mdi-account-plus"></i>
                    </Button>
                </Link>
            </>
        )
    };

    return <LoadingHandlerScreen isLoading={isLoading} component={
        <Col className="col-12">
            <Card>
                <CardBody>
                    <Row>
                        <Col md="4">
                            <div className="mb-3">
                                <Label htmlFor="validationCustom02">Categoria</Label>
                                <ReactSelect
                                    options={optionGroup}
                                    classNamePrefix="select2-selection"
                                    closeMenuOnSelect={true}
                                    components={animatedComponents}
                                    placeholder="Selecione uma categoria"
                                    value={category}
                                    onChange={selectedOption => { setCategory(selectedOption) }}
                                />
                            </div>
                        </Col>
                    </Row>
                    <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive bordered data={data} />
                </CardBody>
            </Card>
        </Col>
    } />
}