import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Badge } from "reactstrap";

import { connect } from "react-redux";

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions";

import "./datatables.scss";
import { Link } from "react-router-dom";
import { useAuth } from "hooks/useAuth";
import { post } from "helpers/api_helper";
import hasImage from "common/hasImage";
import LoadingHandlerScreen from "components/Common/LoadingHandlerScreen";
import isAllowed from "components/Common/IsAllowed";

const StatusCell = ({ status, name }) => {

  var status_color = 'danger';

  if (status == 1) {
    status_color = 'warning';
  } else if (status == 2) {
    status_color = 'info';
  } else if (status == 3) {
    status_color = 'success';
  };

  return (
    <Badge className={"rounded-pill bg-" + status_color}>{name}</Badge>
  );
};


const Orders = (props) => {

  const { userData: { id_admin, orders_permissions: { manage_orders }, } } = useAuth();

  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const ActionButton = ({ id }) => {
    return (
      <>
        <Link to={`/pedido-detalhes/${id}`}>
          <Button color="primary" size="sm" className="waves-effect waves-light">
            <i className="mdi mdi-magnify"></i>
          </Button>
        </Link>
        {" "}
        {
          isAllowed(manage_orders) && (
            <Link to={`/pedido-editar/${id}`}>
              <Button color="primary" size="sm" className="waves-effect waves-light">
                <i className="mdi mdi-pencil"></i>
              </Button>
            </Link>
          )
        }
      </>
    );
  };

  const breadcrumbItems = [
    { title: "Dashboard", link: "/dashboard" },
    { title: "Pedidos", link: "#" },
  ];

  useEffect(() => {
    props.setBreadcrumbItems('Pedidos', breadcrumbItems);

    const fetchData = async () => {
      const payload = { id_admin_user: id_admin };
      const response = await post('order_list.php', payload);

      if (response.success) {

        const origin_orders = response.orders;

        const temp_orders = origin_orders.map(obj => {
          return {
            ...obj,
            acao: <ActionButton id={obj.id_order} />,
            status: <StatusCell status={obj.status_order} name={obj.status_name} />,
            image: <img alt={obj.name} className="avatar-xs me-2 rounded-circle" src={hasImage(obj.image)} />,
          }
        });

        setOrderList(temp_orders);
        setIsLoading(false);
      };
    };

    fetchData();
  }, [id_admin]);

  const data = {
    columns: [
      {
        label: "ID",
        field: "id_order",
        width: 150,
      },
      {
        label: "Imagem",
        field: "image",
        sort: "disabled",
        width: 150,
      },
      {
        label: "Comprador",
        field: "buyer_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Empresa",
        field: "company_name",
        sort: "asc",
        width: 270,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Valor",
        field: "order_value",
        sort: "asc",
        width: 100,
      },
      {
        label: "Data de Compra",
        field: "order_date",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ação",
        field: "acao",
        width: 100,
        sort: "disabled"
      },
    ],
    rows: orderList,
  };

  return <LoadingHandlerScreen isLoading={isLoading} component={
    <React.Fragment>

      <MetaTags>
        <title>Pedidos | Terra</title>
      </MetaTags>

      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <MDBDataTable infoLabel={["Monstrando", "até", "de", "resultados"]} paginationLabel={['Anterior', 'Próximo']} searchLabel="Pesquisar" entriesLabel="Mostrar" noRecordsFoundLabel="Nenhum resultado encontrado" responsive noBottomColumns bordered data={data} />
            </CardBody>
          </Card>
        </Col>
      </Row>

    </React.Fragment>
  } />
}

export default connect(null, { setBreadcrumbItems })(Orders);